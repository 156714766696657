.Phone-Frame{
    width: 280px;
    height: 600px;
    position: relative;
    z-index: 2;
    display: block;
    clear: right;
    background-image: none;
}
.video2{
    position: absolute;
    top: 15px;
    left: 0;
    display: block;
    width: 280px;
    height: 520px;
}
.phone-container2 {
    position: absolute;
}
.Phone-div{
    width: 100%;
    height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Col{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.Col > h2{
    margin-bottom: 3rem;
    color: white;
    font-size: 18px;
    text-align: center;
    width: 75%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 900px) and (min-width: 700px)  {
 .Video-Container .Col{
    max-width: 50%;
    flex: 0 0 50%;
 }
 .Video-Container .Col3{
    justify-content: center;
    align-items: center;
    display: flex;
    max-width: 100%;
    flex: 0 0 100%;
    margin-top: 25px;
 }
 .Phone-div{
     display: flex;
     align-items: center;
     justify-content: center;
 }
}
@media (max-width: 550px) {
    .Phone-div{
        align-items: center;
        justify-content: center;
        display: flex;
        margin-bottom: 30px;
    }
    .VideoInteraction-div{
        margin-top: 7rem;
    }
    .Col{
        padding: 0;
    }
    .Video-Container{
        padding-left: 0;
    }
}

