footer {
  background-color: var(--mainBackground);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  margin-top: 50px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  text-align: center;
}

footer img {
  width: 300px;
  height: 80px;
  margin-bottom: 1rem;
}

footer .f-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  margin: 20px 0;
}

footer .f-links a {
  color: #ccc;
}

footer .f-links a:hover {
  color: #fff;
}

footer .f-links div {
  height: 20px;
  width: 1px;
  background-color: #ccc;
}

footer p {
  margin: 0;
  color: #888;
}
