.heading {
  text-align: center;
  color: #fff;
  margin: 40px 0;
  padding: 0 10px;
}

.heading span {
  color: var(--primaryColor);
  position: relative;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.heading span::before {
  position: absolute;
  top: 50%;
  right: -60px;
  content: '';
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50px;
  height: 1px;
  margin-top: -1px;
  background: var(--primaryColor);
}

.heading span::after {
  position: absolute;
  top: 50%;
  left: -60px;
  content: '';
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50px;
  height: 1px;
  margin-top: -1px;
  background: var(--primaryColor);
}

.heading h2 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
}
