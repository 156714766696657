.btn-primary {
  background-color: var(--primaryColor);
  border: none;
  box-shadow: none;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: var(--primaryHover);
  box-shadow: none !important;
}
