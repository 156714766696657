.navbar {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  opacity: 0.98;
  width: 100%;
  z-index: 100;
}

.navbar img {
  text-align: center;
  width: 300px;
  height: 80px;
}

.Nav__black{
  z-index: 2;
  width: 100%;
  background-color: #12141de1;
}

/* .navbar button {
  border-radius: 50px;
  font-size: 18px;
  letter-spacing: 2px;
} */

/* @media (max-width: 550px) {
  .navbar {
    justify-content: center;
  }
  .navbar button {
    display: none;
  }
} */
