@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
:root {
  --headingFont: 'Open Sans', sans-serif;
  --bodyFont: 'Inter', sans-serif;
  --primaryColor: #43a8fa;
  --primaryHover: #2a92e7;
  --mainBackground: #12141d;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--bodyFont);
  background-color: #1e2029;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--headingFont);
}

a,
a:hover {
  text-decoration: none;
}

.btn-primary {
  background-color: var(--primaryColor);
  border: none;
  box-shadow: none;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: var(--primaryHover);
  box-shadow: none !important;
}

.navbar {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  opacity: 0.98;
  width: 100%;
  z-index: 100;
}

.navbar img {
  text-align: center;
  width: 300px;
  height: 80px;
}

.Nav__black{
  z-index: 2;
  width: 100%;
  background-color: #12141de1;
}

/* .navbar button {
  border-radius: 50px;
  font-size: 18px;
  letter-spacing: 2px;
} */

/* @media (max-width: 550px) {
  .navbar {
    justify-content: center;
  }
  .navbar button {
    display: none;
  }
} */

.header {
  background-image: url(/static/media/Background.693658e3.png);
  background-position: center;
  background-size: cover;
  height: 650px;
  color: #fff;
  padding: 20px 0;
  overflow-y: visible;
}

.header .row {
  display: flex;
  align-items: center;
  margin-top: 6rem;
}

.header h4 {
  text-shadow: rgba(8, 35, 56, 0.8) 0px 4px 3px;
  font-weight: 800;
  margin-bottom: 15px;
}

.header h4 i {
  color: var(--primaryColor);
  width: 35px;
}

.header h4 span {
  border-bottom: 1px solid var(--primaryColor);
  padding-bottom: 5px;
}

.header p {
  color: white;
  font-size: 18px;
  text-shadow: rgba(8, 35, 56, 0.8) 0px 4px 3px;
}

.header img {
  width: 280px;
  height: 600px;
  position: relative;
  z-index: 2;
  display: block;
  clear: right;
  background-image: none;
}

.header .phone-container {
  position: absolute;
  top: -200px;
}

.header .phone-container .video {
  position: absolute;
  top: 16px;
  left: 0px;
  display: block;
  width: 280px;
  height: 520px;
}

.stores img {
  width: 150px;
  height: 50px;
  margin: 0 10px;
  z-index: 1;
}

@media (max-width: 550px) {
  .phone-container {
    display: none;
  }
  .stores {
    display: flex;
    justify-content: center;
  }
  .header p {
    padding: 5px 10px;
    width: 97%;
  }
  .header h4 i {
    padding-left: 10px;
  }
  
  .header h4 span {
    padding-left: 10px;
  }
  .stores img {
    width: 120px;
    height: 50px;
  }
  .header {
    height: 860px;
  }
}

.heading {
  text-align: center;
  color: #fff;
  margin: 40px 0;
  padding: 0 10px;
}

.heading span {
  color: var(--primaryColor);
  position: relative;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.heading span::before {
  position: absolute;
  top: 50%;
  right: -60px;
  content: '';
  transform: translateY(-50%);
  width: 50px;
  height: 1px;
  margin-top: -1px;
  background: var(--primaryColor);
}

.heading span::after {
  position: absolute;
  top: 50%;
  left: -60px;
  content: '';
  transform: translateY(-50%);
  width: 50px;
  height: 1px;
  margin-top: -1px;
  background: var(--primaryColor);
}

.heading h2 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
}

.contact-info {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--mainBackground);
  height: 400px;
  margin-bottom: 60px;
  border-bottom: 5px solid var(--primaryColor);
}

.contact-info .fa-envelope-open-text {
  font-size: 150px;
  padding: 20px 0;
}

.contact-info .ci-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-top: 50px;
  font-size: 18px;
}

.contact-info .ci-bottom i {
  color: var(--primaryColor);
}

.contact-form form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
}

.contact-form {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Contact-Form-div{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.contact-form > input {
  background-color: var(--mainBackground);
  border: none;
  padding: 30px;
  font-size: 20px;
  box-shadow: none;
  color: #fff;
  margin-top: 10px;
  width: 100%;
}

.contact-form > input::-webkit-input-placeholder {
  color: rgb(184, 184, 184);
}

.contact-form > input:-ms-input-placeholder {
  color: rgb(184, 184, 184);
}

.contact-form > input::placeholder {
  color: rgb(184, 184, 184);
}

.contact-form .textarea {
  padding: 10px 30px !important;
  resize: none;
  height: 200px;
}

.contact-form > button {
  width: 100%;
  font-size: 18px;
  padding: 15px 0;
  display: block;
  margin-top: 1rem;
  background-color: var(--primaryColor);
  border: 1px solid transparent;
}
.contact-form > button:hover {
  background-color: var(--primaryHover);
  color: white;
}

@media (max-width: 770px) {
  .contact-info .ci-bottom {
    flex-direction: column;
  }
}
@media (max-width: 550px) {
  .contact-form {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
  }
  .Contact-Form-div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
}
.try-it {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.try-it h4 {
  color: #ccc;
  margin-bottom: 30px;
}

.try-it hr {
  background-color: #fff;
  height: 1px;
  width: 30%;
  border: none;
  margin: 40px 0;
  opacity: 0.2;
}

.try-it h5 {
  color: #ccc;
}

.try-it .social-media {
  display: flex;
  margin: 10px 0;
}

.try-it .social-media div {
  background-color: var(--mainBackground);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  margin: 0 10px;
  cursor: pointer;
}

.try-it .social-media .fb:hover {
  background-color: #4164ac;
}

.try-it .social-media .tw:hover {
  background-color: #1d9cea;
}

.try-it .social-media .wapp:hover {
  background-color: #5acc65;
}

.try-it .social-media .inst:hover {
  background-color: #de4c7b;
}

footer {
  background-color: var(--mainBackground);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  margin-top: 50px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  text-align: center;
}

footer img {
  width: 300px;
  height: 80px;
  margin-bottom: 1rem;
}

footer .f-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  margin: 20px 0;
}

footer .f-links a {
  color: #ccc;
}

footer .f-links a:hover {
  color: #fff;
}

footer .f-links div {
  height: 20px;
  width: 1px;
  background-color: #ccc;
}

footer p {
  margin: 0;
  color: #888;
}

.Phone-Frame{
    width: 280px;
    height: 600px;
    position: relative;
    z-index: 2;
    display: block;
    clear: right;
    background-image: none;
}
.video2{
    position: absolute;
    top: 15px;
    left: 0;
    display: block;
    width: 280px;
    height: 520px;
}
.phone-container2 {
    position: absolute;
}
.Phone-div{
    width: 100%;
    height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Col{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.Col > h2{
    margin-bottom: 3rem;
    color: white;
    font-size: 18px;
    text-align: center;
    width: 75%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 900px) and (min-width: 700px)  {
 .Video-Container .Col{
    max-width: 50%;
    flex: 0 0 50%;
 }
 .Video-Container .Col3{
    justify-content: center;
    align-items: center;
    display: flex;
    max-width: 100%;
    flex: 0 0 100%;
    margin-top: 25px;
 }
 .Phone-div{
     display: flex;
     align-items: center;
     justify-content: center;
 }
}
@media (max-width: 550px) {
    .Phone-div{
        align-items: center;
        justify-content: center;
        display: flex;
        margin-bottom: 30px;
    }
    .VideoInteraction-div{
        margin-top: 7rem;
    }
    .Col{
        padding: 0;
    }
    .Video-Container{
        padding-left: 0;
    }
}


/* Third Party */

/* Theme */

/* Components */

