.header {
  background-image: url(../../assets/Background.png);
  background-position: center;
  background-size: cover;
  height: 650px;
  color: #fff;
  padding: 20px 0;
  overflow-y: visible;
}

.header .row {
  display: flex;
  align-items: center;
  margin-top: 6rem;
}

.header h4 {
  text-shadow: rgba(8, 35, 56, 0.8) 0px 4px 3px;
  font-weight: 800;
  margin-bottom: 15px;
}

.header h4 i {
  color: var(--primaryColor);
  width: 35px;
}

.header h4 span {
  border-bottom: 1px solid var(--primaryColor);
  padding-bottom: 5px;
}

.header p {
  color: white;
  font-size: 18px;
  text-shadow: rgba(8, 35, 56, 0.8) 0px 4px 3px;
}

.header img {
  width: 280px;
  height: 600px;
  position: relative;
  z-index: 2;
  display: block;
  clear: right;
  background-image: none;
}

.header .phone-container {
  position: absolute;
  top: -200px;
}

.header .phone-container .video {
  position: absolute;
  top: 16px;
  left: 0px;
  display: block;
  width: 280px;
  height: 520px;
}

.stores img {
  width: 150px;
  height: 50px;
  margin: 0 10px;
  z-index: 1;
}

@media (max-width: 550px) {
  .phone-container {
    display: none;
  }
  .stores {
    display: flex;
    justify-content: center;
  }
  .header p {
    padding: 5px 10px;
    width: 97%;
  }
  .header h4 i {
    padding-left: 10px;
  }
  
  .header h4 span {
    padding-left: 10px;
  }
  .stores img {
    width: 120px;
    height: 50px;
  }
  .header {
    height: 860px;
  }
}
