html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--bodyFont);
  background-color: #1e2029;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--headingFont);
}

a,
a:hover {
  text-decoration: none;
}
