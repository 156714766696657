.contact-info {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--mainBackground);
  height: 400px;
  margin-bottom: 60px;
  border-bottom: 5px solid var(--primaryColor);
}

.contact-info .fa-envelope-open-text {
  font-size: 150px;
  padding: 20px 0;
}

.contact-info .ci-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-top: 50px;
  font-size: 18px;
}

.contact-info .ci-bottom i {
  color: var(--primaryColor);
}

.contact-form form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
}

.contact-form {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Contact-Form-div{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.contact-form > input {
  background-color: var(--mainBackground);
  border: none;
  padding: 30px;
  font-size: 20px;
  box-shadow: none;
  color: #fff;
  margin-top: 10px;
  width: 100%;
}

.contact-form > input::placeholder {
  color: rgb(184, 184, 184);
}

.contact-form .textarea {
  padding: 10px 30px !important;
  resize: none;
  height: 200px;
}

.contact-form > button {
  width: 100%;
  font-size: 18px;
  padding: 15px 0;
  display: block;
  margin-top: 1rem;
  background-color: var(--primaryColor);
  border: 1px solid transparent;
}
.contact-form > button:hover {
  background-color: var(--primaryHover);
  color: white;
}

@media (max-width: 770px) {
  .contact-info .ci-bottom {
    flex-direction: column;
  }
}
@media (max-width: 550px) {
  .contact-form {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
  }
  .Contact-Form-div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
}