.try-it {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.try-it h4 {
  color: #ccc;
  margin-bottom: 30px;
}

.try-it hr {
  background-color: #fff;
  height: 1px;
  width: 30%;
  border: none;
  margin: 40px 0;
  opacity: 0.2;
}

.try-it h5 {
  color: #ccc;
}

.try-it .social-media {
  display: flex;
  margin: 10px 0;
}

.try-it .social-media div {
  background-color: var(--mainBackground);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  margin: 0 10px;
  cursor: pointer;
}

.try-it .social-media .fb:hover {
  background-color: #4164ac;
}

.try-it .social-media .tw:hover {
  background-color: #1d9cea;
}

.try-it .social-media .wapp:hover {
  background-color: #5acc65;
}

.try-it .social-media .inst:hover {
  background-color: #de4c7b;
}
